import React from 'react'
import { graphql } from 'gatsby'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import { BLOCKS } from '@contentful/rich-text-types'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { Center } from "@chakra-ui/react"
import { Prose } from '@nikolovlazar/chakra-ui-prose'

export function Head() {
  return (<>
    <html lang="en" />
    <meta httpEquiv="content-language" content="en" />
    <title>JJR Jewelry</title>
    <meta property="og:title" content="JJR Jewelry" />
    <meta property="og:description" content="Jonathan Joseph Rosse Jewelry" />
    <meta property="og:image" content="/images/og_image.jpg" />
  </>)
}

const Page = ({ data }) => {

  const options = {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        const { gatsbyImage, description } = node.data.target
        return (
          <GatsbyImage
            image={getImage(gatsbyImage)}
            alt={description}
          />
        )
      },
    },
  };

  return (
    <Center py="8" textAlign="center" flex="auto">
      <Prose my="auto">
        {data.contentfulWebpage.content?.raw && renderRichText(data.contentfulWebpage.content, options)}
      </Prose>
    </Center>
  )
}

export default Page

export const data = graphql`
  query pageQuery($slug: String) {
    contentfulWebpage(slug: { eq: $slug }) {
      title
      content { raw }
    }
  }
`;